











import { mapActions, mapState } from 'vuex'
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action } from 'vuex-class';
import { IError } from '@/types/interfaces';

@Component
export default class ProductsPage extends Vue {
  private loading: Boolean = true

  @Action('login', { namespace: 'shopify' }) loginShopify: any;

  mounted() {
    this.loginShopify(this.$route.query).then((result: any) => {
      this.$router.push('/')
    }).catch((err: IError) => {
      this.$router.push({ name: 'users-auth' })
    })
  }
}
